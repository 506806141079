import { trace, context, propagation } from '@opentelemetry/api'
import { type FetchArgs, type FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { type BaseQueryApi, fetchBaseQuery } from '@reduxjs/toolkit/query'
import { showSnackbar } from 'components/snackbar'
import { replaceGoodsHolderText } from 'utils/goodsholderContext'

import { dispatch } from '.'
const tracer = trace.getTracer('rasn-processing')

export const customBaseQuery = (baseQueryOptions: FetchBaseQueryArgs) => async (args: FetchArgs | string, api: BaseQueryApi, extraOptions: Record<string, unknown>) => {
  if (typeof args === 'string') {
    args = { url: args }
  }
  args.url = cleanUrl(args.url) // required to call a root endpoint with a mutation, the '/' is added automatically

  // OTel trace propagation through Headers, also requires mainSplitApi config to ensure these are passed
  // This is sent as `Traceparent`
  let headers: Headers
  if (args.headers instanceof Headers) {
    headers = args.headers
  } else if (Array.isArray(args.headers)) {
    headers = new Headers(args.headers as Array<[string, string]>)
  } else {
    headers = new Headers()
  }
  const unitId = localStorage.getItem('unitId')
  headers.set('X-unitId', unitId ?? '')
  const span = tracer.startSpan(`HTTP ${args.url}`, {
    attributes: {
      'http.url': args.url,
      'http.method': args.method ?? 'GET',
      unitId: unitId ?? ''
    }
  })
  const activeContext = trace.setSpan(context.active(), span)
  propagation.inject(activeContext, headers, {
    set: (carrier, key, value) => { carrier.set(key, value) }
  })
  args.headers = headers
  // End OTel trace header config

  const result = await context.with(trace.setSpan(context.active(), span), async () => {
    return await fetchBaseQuery(baseQueryOptions)(args, api, extraOptions)
  })

  span.end()

  // Error handling
  if (result.error != null) {
    const error = result.error

    switch (error.status) {
      case 401:
        dispatch(showSnackbar('Unauthorized', 'error'))
        break
      case 403:
        dispatch(showSnackbar('Forbidden', 'error'))
        break
      case 400:
      case 500: {
        const errorData = error.data as { message?: string }
        dispatch(showSnackbar(errorData.message ? replaceGoodsHolderText(errorData.message) : 'Internal server error', 'error'))
        break
      }
      default:
    }

    return { error: { status: result.meta?.response?.status, data: error.data } }
  }

  return result
}

const cleanUrl = (old: string) => {
  return old?.endsWith('/') ? old.substring(0, old.length - 1) : old
}
